import { Directive, ElementRef, Renderer2 } from '@angular/core';

declare var $;

@Directive({
  selector: '[appSvgTransform]'
})
export class SvgTransformDirective {

  constructor(element: ElementRef, renderer: Renderer2) {
    var $img = element.nativeElement;
    var imgID = $img.id;
    var imgClass = $img.className;
    var imgURL = $img.src;
    $.get(imgURL, function (data) {
      var $svg = $(data).find('svg');
      if (typeof imgID !== 'undefined') {
        $svg = $svg.attr('id', imgID);
      }
      if (typeof imgClass !== 'undefined') {
        $svg = $svg.attr('class', imgClass + ' replaced-svg');
      }
      $svg = $svg.removeAttr('xmlns:a');
      if (!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
        $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'))
      }
      $img.replaceWith($svg[0]);
    }, 'xml');

  }

}
