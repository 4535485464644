import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { User } from '../models/user.model';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';


@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private token = new BehaviorSubject<string>(null);
    private user = new BehaviorSubject<User>(null);

    constructor(private http: HttpClient, public router: Router) { }

    public get tokenValue(): string {
        return this.token.value;
    }
    public get userValue(): User {
        return this.user.value;
    }
    public setTokenValue(token: string): any {
        this.token.next(token);
    }
    public setUserValue(user: User): any {
        this.user.next(user);
    }



    login(username: string, password: string) {
        return this.http.post<any>(`${environment.apiUrlpublic}${environment.auth}`, { email: username, password })
            .pipe(map(response => {

                // login successful if there's a token in the response
                if (response && response.token) {
                    // store user details and token in local storage to keep user logged in between page refreshes
                    //console.log(response.token)
                    localStorage.setItem('token', response.token);
                    this.token.next(response.token);
                    return response;
                }
                return false;

            }));
    }

    resetPassword(username: string) {
        return this.http.post<any>(`${environment.apiUrlpublic}${environment.resetpassword}`, { email: username })
            .pipe(map(() => {
                return true;
            }));
    }

    registerAccount(data: User) {
        return this.http.post<any>(`${environment.apiUrlpublic}${environment.register}`, { ...data, name: data.username })
            .pipe(map(() => {
                return true;
            }));
    }

    logout() {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        this.token.next(null);
        this.router.navigate(['/login']);
        this.setUserValue(null);
    }
}