import {
    HttpInterceptor, HttpRequest, HttpHandler, HttpSentEvent, HttpHeaderResponse,
    HttpProgressEvent, HttpResponse, HttpUserEvent
} from '@angular/common/http';
import { Injectable, ErrorHandler } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthenticationService } from './authentication.service';

@Injectable({
    providedIn: 'root',
})
export class HeaderService implements HttpInterceptor, ErrorHandler {

    constructor(public authenticationService: AuthenticationService) { }

    handleError(error: any): void {
        console.error('interceeeptor ', error);
    }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent
        | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
        const token = this.authenticationService.tokenValue;
        let headers;
        if (token) {
            headers = {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            };
        } else {
            headers = {
                'Content-Type': 'application/json'
            };
        }
        const clone = req.clone({ setHeaders: headers });

        return next.handle(clone);
    }

}
