import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginPageComponent } from './pages/login-pages/login-page/login-page.component';
import { LoginRegisterPageComponent } from './pages/login-pages/login-register-page/login-register-page.component';
import { LoginForgotComponent } from './pages/login-pages/login-forgot/login-forgot.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { AuthGuard } from 'src/core-module/guards/auth.guard';
import { FiltersResolver } from 'src/core-module/resolvers/filters.resolver';

const routes: Routes = [
  {
    path: '', resolve: { filters: FiltersResolver }, component: DashboardComponent, canActivate: [AuthGuard],
  },
  { path: 'login', component: LoginPageComponent },
  { path: 'register', component: LoginRegisterPageComponent },
  { path: 'login-forgot', component: LoginForgotComponent },
  { path: '**', redirectTo: '/' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    { scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled', onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }



