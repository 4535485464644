import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { LoginPageComponent } from './pages/login-pages/login-page/login-page.component';
import { LoaderComponent } from './components/loader/loader.component';
import { CoreModule } from 'src/core-module/core.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginForgotComponent } from './pages/login-pages/login-forgot/login-forgot.component';
import { LoginRegisterPageComponent } from './pages/login-pages/login-register-page/login-register-page.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { AuthenticationService } from 'src/core-module/services/authentication.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NavbarComponent } from './components/navbar/navbar.component';
import { FooterComponent } from './components/footer/footer.component';
import { FiltersComponent } from './layouts/filters/filters.component';
import { ContentComponent } from './layouts/content/content.component';
import { SvgTransformDirective } from './directives/svg-transform';
import { SelectModule } from 'ng-select';

@NgModule({
  declarations: [
    AppComponent,
    LoginPageComponent,
    LoaderComponent,
    LoginRegisterPageComponent,
    LoginForgotComponent,
    DashboardComponent,
    NavbarComponent,
    FooterComponent,
    FiltersComponent,
    ContentComponent,
    SvgTransformDirective
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    SelectModule,
    FormsModule,
    ReactiveFormsModule,
    CoreModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {

  constructor(
    private authenticationService: AuthenticationService,
  ) {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      this.authenticationService.setUserValue(user);
      this.authenticationService.setTokenValue(localStorage.getItem('token'));
    }
  }
}
