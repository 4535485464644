import { Component, OnInit, Input } from '@angular/core';
import { I18nPluralPipe } from '@angular/common';
import { DataService } from 'src/core-module/services/data.service';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss']
})
export class ContentComponent implements OnInit {
  itemPluralMapping = {
    'result': {
      '=0': '0 results',
      '=1': '1 result',
      'other': '# results'
    }
  };

  @Input() data: any = [];
  constructor(private dataService: DataService) { }

  ngOnInit() {
  }

  countVisit(video) {
    this.dataService.postVisit(video.id, JSON.parse(localStorage.getItem('user')).token).subscribe(res => {
      //console.log("-----");
      //console.log(res);
    });
  }

}
