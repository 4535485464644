import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { IFilters } from 'src/app/layouts/filters/filters.component';


@Injectable({ providedIn: 'root' })
export class DataService {

    constructor(private http: HttpClient) { }


    searchByFilters(data?: IFilters) {
        const opts: any = {};
        if (data) {
            if (data.search) { opts.search = data.search; }
            if (data.partner_id) { opts.partner_id = parseInt(data.partner_id, 10); }
            if (data.customer_id) { opts.customer_id = parseInt(data.customer_id, 10); }
            if (data.industry_id) { opts.industry_id = parseInt(data.industry_id, 10); }
            if (data.sic_codes) { opts.sic_id = parseInt(data.sic_codes, 10); }
            if (data.subtitles) { opts.subtitles = data.subtitles }
            if (data.country_id) { opts.country_id = parseInt(data.country_id, 10); }
            if (data.language_id) { opts.language_id = parseInt(data.language_id, 10); }
        }
        return this.http.post<any>(`${environment.apiUrlpublic}${environment.searchByData}`, { ...opts });
    }
    getFilters() {
        return this.http.get<any>(`${environment.apiUrlpublic}${environment.filters}`);
    }
    getSubIndustries(industry: number) {
        return this.http.get<any>(`${environment.apiUrlpublic}${environment.subindustries}/${industry}`);
    }
    postVisit(videoId: string, userToken: string) {
        return this.http.post<any>(`${environment.apiUrlpublic}${environment.countvisit}`, { videoId, userToken });
    }


}