import { Component, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { PwaService } from '../core-module/services/pwa.service';
import { ComunicationService, LoadingProps } from '../core-module/services/comunication.service';
import { debounceTime } from 'rxjs/internal/operators/debounceTime';
declare var $: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styles: []
})
export class AppComponent {
  loading: LoadingProps = { value: true };


  constructor(private cdRef: ChangeDetectorRef, public router: Router, public pwaService: PwaService, private comunicationService: ComunicationService) {
    this.getMobileOperatingSystem();
  }

  ngOnInit(): void {
    this.comunicationService.getLoadingStatus().pipe(
      debounceTime(10),
    ).subscribe((res: LoadingProps) => {
      this.loading = res;
    });
    this.loading = { value: false };
  }

  getMobileOperatingSystem() {
    const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      $('body').addClass('android');
      $('html').addClass('android');
      return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
      $('body').addClass('iOS');
      $('html').addClass('iOS');
      return "iOS";

    }
    $('html').addClass('otro');
    $('body').addClass('otro');
    return "unknown";
  }

}
