import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from 'src/core-module/services/authentication.service';
import { Observable } from 'rxjs';
import { ComunicationService } from 'src/core-module/services/comunication.service';
import { User } from 'src/core-module/models/user.model';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';




@Component({
    templateUrl: 'login-page.component.html',
    styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit {
    loginForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;
    wrongPassword = false;
    user: User;
    showPassword = false;
    selectedevice: any;
    constructor(
        private comunicationService: ComunicationService,
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        config: NgbModalConfig, private modalService: NgbModal
    ) {
        if (this.authenticationService.tokenValue) {
            this.router.navigate(['/']);
        }
        this.comunicationService.setLoading({ value: false });
    }

    ngOnInit() {
        this.loginForm = this.formBuilder.group({
            username: ['', Validators.email],
            password: ['', Validators.required]
        });
    }

    get f() { return this.loginForm.controls; }


    onSubmit(dialog) {
        this.submitted = true;
        if (this.loginForm.invalid) {
            return;
        }
        this.comunicationService.setLoading({ value: true });
        this.authenticationService.login(this.f.username.value, this.f.password.value)
            .subscribe(
                user => {
                    if (user) {
                        this.comunicationService.setLoading({ value: false });
                        this.setUser(user);
                        this.router.navigate(['/']);
                    }
                }, error => {
                    //console.log(error);
                    this.modalService.open(dialog);
                    this.comunicationService.setLoading({ value: false });
                });
    }



    setUser(user) {
        localStorage.setItem('user', JSON.stringify(user));
        this.authenticationService.setUserValue(user);
    }


    confirm(close) {
        close();
    }

}
