import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';


export interface CustomMessage {
    key: string;
    data: any;
}
export interface LoadingProps {
    value: boolean;
}



@Injectable({
    providedIn: 'root',
})
export class ComunicationService {
    private loading = new Subject<LoadingProps>();;
    private subject = new Subject<CustomMessage>();

    sendMessage(data: CustomMessage) {
        this.subject.next(data);
    }


    getMessage(): Observable<CustomMessage> {
        return this.subject.asObservable();
    }

    setLoading(value: LoadingProps) {
        this.loading.next(value);
    }


    getLoadingStatus(): Observable<LoadingProps> {
        return this.loading.asObservable();
    }




}