import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/core-module/services/authentication.service';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ComunicationService } from 'src/core-module/services/comunication.service';

@Component({
    selector: 'app-login-forgot',
    templateUrl: './login-forgot.component.html',
    styleUrls: ['./login-forgot.component.scss']
})
export class LoginForgotComponent implements OnInit {
    loginForm: FormGroup;
    loading = false;
    submitted = false;
    showPassword = false;
    message: any;
    title: string;
    error: any;
    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private authenticationService: AuthenticationService,
        private comunicationService: ComunicationService,
        config: NgbModalConfig, private modalService: NgbModal
    ) {
        config.backdrop = 'static';
        config.keyboard = false;
        this.comunicationService.setLoading({ value: false });
    }

    ngOnInit() {
        this.loginForm = this.formBuilder.group({
            username: ['', Validators.email]
        }, { validators: [] });
    }

    get f() { return this.loginForm.controls; }

    onSubmit(dialog) {
        this.submitted = true;
        if (this.loginForm.invalid) {
            return;
        }
        this.comunicationService.setLoading({ value: true });
        this.authenticationService.resetPassword(this.f.username.value).subscribe((res) => {
            this.comunicationService.setLoading({ value: false });
            this.title = 'Congratulations!';
            this.message = 'Your password has been reset successfully. Please check you email!';
            this.modalService.open(dialog);
        }, error => {
            this.error = true;
            // Dealing with error
            this.title = 'Attention!';
            this.message = error.error.message;
            this.modalService.open(dialog);
            //console.log(error);
            this.comunicationService.setLoading({ value: false });
        })
    }

    confirm(close) {
        close('Save click');
        if (!this.error) {
            this.router.navigate(['/login']);
        } else {
            this.error = false;
        }
    }
}


