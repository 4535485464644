import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from 'src/core-module/services/authentication.service';
import { User } from 'src/core-module/models/user.model';
import { Observable } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { ComunicationService } from 'src/core-module/services/comunication.service';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { countries } from 'src/assets/countries';

@Component({
  templateUrl: 'login-register-page.component.html',
  styleUrls: ['./login-register-page.component.scss']
})
export class LoginRegisterPageComponent implements OnInit {
  countries = countries;
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  user: User;
  showPassword = false;
  errors: string[];
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private comunicationService: ComunicationService,
    private authenticationService: AuthenticationService,
    config: NgbModalConfig, private modalService: NgbModal) {
    // redirect to home if already logged in
    if (this.authenticationService.tokenValue) {
      this.router.navigate(['/']);
    }
    this.comunicationService.setLoading({ value: false });

  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      company: ['', Validators.required],
      password: ['', Validators.required],
      email: ['', [Validators.email, Validators.required]],
      country: [''],
      sap: [''],
      subscription: ['']
    }, { validators: [] });
    this.loginForm.controls.subscription.setValue(false);
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  onSubmit(dialog, dialog2) {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }
    this.comunicationService.setLoading({ value: true });
    const user = new User(this.loginForm.value);
    this.authenticationService.registerAccount(user).subscribe(() => {
      this.comunicationService.setLoading({ value: false });
      this.modalService.open(dialog);
    }, (error: any) => {
      // Dealing with error
      //console.log(Object.values(error.error.errors));
      this.errors = Object.values(error.error.errors);
      this.modalService.open(dialog2);
      this.comunicationService.setLoading({ value: false });
    });
  }

  setUser(user: User) {
    localStorage.setItem('user', JSON.stringify(user));
    this.authenticationService.setUserValue(user);
  }
  confirm(close) {
    close('Save click');
    this.router.navigate(['/login']);
  }
}

