


import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';
import { ComunicationService } from '../services/comunication.service';
import { DataService } from '../services/data.service';

@Injectable({
    providedIn: 'root',
})
export class FiltersResolver {

    constructor(
        private router: Router, private comunicationService: ComunicationService,
        private dataService: DataService, private authenticationService: AuthenticationService) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        this.comunicationService.setLoading({ value: true });
        return new Observable(observer => {
            this.dataService.getFilters().subscribe(res => {
                observer.next(res.filters);
                observer.complete();
                this.comunicationService.setLoading({ value: false });
            }, (err) => {
                this.authenticationService.logout();
                return;
            });
        });
    }

}