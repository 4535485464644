import { Component, OnInit } from '@angular/core';
import { IFilters } from 'src/app/layouts/filters/filters.component';
import { DataService } from 'src/core-module/services/data.service';
import { ComunicationService } from 'src/core-module/services/comunication.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  results: any;
  filters: any;
  constructor(private route: ActivatedRoute, private comunicationService: ComunicationService, private dataService: DataService) {
    this.filters = this.route.snapshot.data.filters;
    this.getData();
  }

  ngOnInit() {
  }

  submitFilters(data: IFilters) {
    this.getData(data);
  }


  private getData(data?: IFilters) {
    this.comunicationService.setLoading({ value: true });
    this.dataService.searchByFilters(data).subscribe((res) => {
      this.results = res.resources;
      //console.dir(this.results);
      this.comunicationService.setLoading({ value: false });
    });
  }
}
