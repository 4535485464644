import { Component, OnInit, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { countries } from 'src/assets/countries';
import { Subject } from 'rxjs/internal/Subject';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { DataService } from 'src/core-module/services/data.service';
import { IOption, SelectComponent } from 'ng-select';
export interface IFilters {
  search: string;
  partner_id: string;
  customer_id: string;
  industry_id: string;
  sic_codes: string;
  subtitles: boolean;
  country_id: string;
  language_id: string;
}
@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss']
})
export class FiltersComponent implements OnInit {
  myOptions: Array<IOption> = [
    { label: 'Belgium', value: 'BE' },
    { label: 'Luxembourg', value: 'LU' },
    { label: 'Netherlands', value: 'NL' }
  ];
  showFilters = false;
  countries = countries;
  @ViewChild('sic_codes', { read: undefined, static: false }) siccode: SelectComponent;
  @ViewChild('sic_code_desc', { read: undefined, static: false }) siccodedesc: SelectComponent;
  txtQueryChanged: Subject<string> = new Subject<string>();
  _data: any;
  @Input('data')
  set data(data: any) {
    const res: any = {};
    res.customers = this.formatOption(data.customers);
    res.partners = this.formatOption(data.partners);
    res.countries = this.formatOption(data.countries);
    res.languages = this.formatOption(data.languages);
    res.industries = this.formatOption(data.industries);
    res.sic_codes = this.formatSicCodes(data.sic_codes, data.sic_codes_descriptions, true);
    res.sic_codes_descriptions = this.formatSicCodes(data.sic_codes, data.sic_codes_descriptions, false);

    this._data = res;
    //console.log(this._data)
  }
  @Output() send: EventEmitter<any> = new EventEmitter<any>();
  filtersForm: FormGroup;
  constructor(private formBuilder: FormBuilder, private dataService: DataService) {
    this.txtQueryChanged.pipe(debounceTime(250))
      .pipe(distinctUntilChanged()) // only emit if value is different from previous value
      .subscribe(model => {
        if (model.search.length >= 3) {
          this.send.emit(model);
        }
      });

  }

  ngOnInit() {
    this.filtersForm = this.formBuilder.group({
      search: [''],
      partner_id: [''],
      customer_id: [''],
      industry_id: [''],
      sic_codes: [''],
      sic_code_desc: [''],
      subtitles: [''],
      country_id: [''],
      language_id: ['']
    });
  }


  get f() { return this.filtersForm.controls; }

  onSubmit(flag?) {
    if (flag === 'sic_codes') {
      this.filtersForm.controls.sic_code_desc.setValue(this.filtersForm.controls.sic_codes.value);
    } else if (flag === 'sic_code_desc') {
      this.filtersForm.controls.sic_codes.setValue(this.filtersForm.controls.sic_code_desc.value);
    }
    if (this.filtersForm.invalid) {
      return;
    }
    this.send.emit(this.filtersForm.value);
  }

  cleanFilters() {
    this.filtersForm.controls.search.setValue('');
    this.filtersForm.controls.partner_id.setValue('');
    this.filtersForm.controls.customer_id.setValue('');
    this.filtersForm.controls.industry_id.setValue('');
    this.filtersForm.controls.country_id.setValue('');
    this.filtersForm.controls.language_id.setValue('');
    this.filtersForm.controls.sic_codes.setValue('');
    this.filtersForm.controls.sic_code_desc.setValue('');
    this.filtersForm.controls.subtitles.setValue('');
    this.send.emit(this.filtersForm.value);
  }

  isDirtyForm() {
    return (this.filtersForm.controls.search.value
      || this.filtersForm.controls.partner_id.value
      || this.filtersForm.controls.customer_id.value
      || this.filtersForm.controls.industry_id.value
      || this.filtersForm.controls.sic_codes.value
      || this.filtersForm.controls.sic_code_desc.value
      || this.filtersForm.controls.country_id.value
      || this.filtersForm.controls.subtitles.value
      || this.filtersForm.controls.language_id.value
    );
  }

  onSubmitDebounce() {
    this.txtQueryChanged.next(this.filtersForm.value);
  }

  formatOption(optionsObject) {
    const result = [];
    const keys = Object.keys(optionsObject);
    const values: any = Object.values(optionsObject);
    for (let i = 0; i < keys.length; i++) {
      result.push({
        value: keys[i],
        label: values[i]
      });
    }
    result.sort(this.compare);
    return result;
  }
  formatSicCodes(optionsObject1, optionsObject2, type) {
    let result = [];
    const keys = Object.values(optionsObject1);
    const values: any = Object.values(optionsObject2);
    for (let i = 0; i < keys.length; i++) {
      result.push({
        value: keys[i].toString(),
        label: type ? keys[i] : values[i]
      });
    }
    result.sort(this.compare);
    if (type) {
      result = result.map(x => {
        x.label = x.label.toString();
        return x;
      });
    }
    return result;
  }

  compare(a, b) {
    if (a.label < b.label) {
      return -1;
    }
    if (a.label > b.label) {
      return 1;
    }
    return 0;
  }

}
