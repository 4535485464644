

export class User {
    id: string;
    password: string;
    token: string;
    name: string;
    username: string;
    company: string;
    email: string;
    country: any;
    sap: string;
    subscription: string;

    constructor(data) {
        this.id = data.id;
        this.password = data.password;
        this.token = data.token;
        this.name = data.name;
        this.username = data.username;
        this.company = data.company;
        this.email = data.email;
        this.country = data.country;
        this.sap = data.sap;
        this.subscription = data.subscription;
    }
}

