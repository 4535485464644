// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.



export const environment = {
  production: false,
  apiUrlpublic: 'https://www.businessonevideos.com/admin/',
  auth: '/user/login',
  register: '/user/register',
  searchByData: '/search',
  filters: '/getFilters',
  subindustries: '/getSubindustries',
  countvisit: '/countvisit',
  resetpassword: '/password/email'
};
/* prod--> apiUrlpublic: 'https://www.businessonevideos.com/admin/', 
   dev --> apiUrlpublic: 'https://www.sawabonacreative.com/tuwebenelhorno/tasman/buscador_videos/gestor-buscador-videos/public',
   local -->   apiUrlpublic: 'http://localhost/tasman/gestor-buscador-videos/',
   */

   /*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
